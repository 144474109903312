import { h } from "preact";
import { Router } from "preact-router";

import Header from "./header";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Pricing from "../routes/pricing";
import PageNames from "./page_names";

const App = () => (
  <div id='app'>
    
    <Router>
      <Home path='/' />
      <Pricing path='/pricing'/>
    </Router>
  </div>
);

export default App;
