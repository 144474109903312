
const PageNames =
{
    Home : "/",
    demo_url : "/demo/demo.html",
    trial_url : "/download_trial",
    pricing : "/pricing",
    contact : "/contact"
};

export default PageNames;