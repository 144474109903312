import { h } from "preact";
import { Link } from "preact-router/match";
import PageNames from "../page_names";

const Header = () => (
  <header class='header'>
    <div class='branding'>
      <div class='container'>
        <nav class='navbar navbar-expand-lg'>
          <div class='site-logo me-5'>
            <a class='navbar-brand' href='index.html'>
              <img
                class='logo-icon me-2'
                src='assets/images/site-logo.svg'
                alt='logo'
              ></img>
            </a>
          </div>

          <button
            class='navbar-toggler collapsed'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navigation'
            aria-controls='navigation'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span> </span>
            <span> </span>
            <span> </span>
          </button>

          <div class='collapse navbar-collapse pt-3 pt-lg-0' id='navigation'>
            <ul class='navbar-nav'>
              <li class='nav-item me-lg-4'>
                <a class='nav-link' href='/'>
                  Product
                </a>
              </li>
              <li class='nav-item me-lg-4'>
                <a class='nav-link' href={PageNames.pricing}>
                  Pricing
                </a>
              </li>
              <li class='nav-item dropdown me-lg-4'>
                <a
                  class='nav-link dropdown-toggle'
                  href='#'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  About Us
                </a>
                <ul
                  class='dropdown-menu dropdown-menu-end rounded shadow menu-animate slideIn'
                  aria-labelledby='navbarDropdown'
                >
                  <li>
                    <a class='dropdown-item has-icon' href='/team.html'>
                      
                    </a>
                  </li>
                  <li>
                    <a class='dropdown-item has-icon' href='/case.html'>
                      Case Study
                    </a>
                  </li>
                  <li>
                    <a class='dropdown-item has-icon' href='/support.html'>
                      Support
                    </a>
                  </li>
                  <li>
                    <a class='dropdown-item has-icon' href='/blog.html'>
                      Blog
                    </a>
                  </li>
                  <li>
                    <a class='dropdown-item has-icon' href='/blog-post.html'>
                      Blog Post
                    </a>
                  </li>
                  <li>
                    <a class='dropdown-item has-icon' href='/contact.html'>
                      Contact
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <ul class='navbar-nav ms-auto'>
              <li class='nav-item me-lg-3'>
                <a class='nav-link mt-1 mb-3 mb-lg-0' href='signin.html'>
                  Sign In
                </a>
              </li>
              <li class='nav-item'>
                <a
                  class='nav-btn btn btn-gradient text-white'
                  href='signup.html'
                >
                  Free Trial
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header> 
);

export default Header;
